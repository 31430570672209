
import { computed, defineComponent, ref, PropType } from 'vue'
import * as _ from 'lodash'
import { Stories } from '../../../types/main'
import StoriesModalSlider from './StoriesModalSlider.vue'

export default defineComponent({
    components: { StoriesModalSlider },
    props: {
        stories: { type: Object as PropType<Stories>, required: true },
        value: { type: Boolean, required: true },
    },
    setup(props, { emit }) {
        const activeSlideIndex = ref(0)
        const incrementSlide = () => {
            if (activeSlideIndex.value + 1 !== props.stories.slides.length) {
                activeSlideIndex.value++
            }
        }
        const sheetStyles = computed(() => ({
            background: `radial-gradient(ellipse at left top, ${props.stories.colors.gradient1} 20%,${props.stories.colors.gradient2})`,
        }))
        return { sheetStyles, activeSlideIndex, emit, incrementSlide }
    },
})
