import { render, staticRenderFns } from "./StoriesModal.vue?vue&type=template&id=4c32913b&scoped=true"
import script from "./StoriesModal.vue?vue&type=script&lang=ts"
export * from "./StoriesModal.vue?vue&type=script&lang=ts"
import style0 from "./StoriesModal.vue?vue&type=style&index=0&id=4c32913b&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c32913b",
  null
  
)

export default component.exports