<template>
    <v-icon :color="color" class="edit-icon ml-2" @click="renameProject">mdi-pencil-circle</v-icon>
</template>

<script>
export default {
    name: 'RenameProjectBtn',
    props: {
        projectId: {
            type: String,
        },
        color: {
            type: String,
        },
    },
    computed: {},
    components: {},
    data: () => ({}),
    methods: {
        renameProject() {
            this.$store.dispatch('project/callRenameProjectDialog', this.projectId)
        },
    },
    mounted() {},
}
</script>

<style lang="sass" scoped>
@import '@/vars.sass'
.edit-icon
    color: white
    opacity: 0.5
.edit-icon:hover
    opacity: 1
    color: $hint-icon-hover-color
    cursor: pointer
</style>
