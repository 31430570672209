<template>
    <v-data-table
        class="mt-5 black--text user-table"
        :headers="headers"
        :items="connectors.filter(c => !c.isChisai)"
        hide-default-footer
    >
        <template v-slot:[`item.connectorType`]="{ item }">
            <v-avatar :color="getConnectorAvatar(item).color" size="36" class="mx-2 my-2">
                <v-img
                    v-if="getConnectorAvatar(item).image"
                    transition="slide-x-transition"
                    :src="connector.image"
                />
                <span v-else>{{ getConnectorAvatar(item).symbol }}</span>
            </v-avatar>
            <span class="body-1">{{
                `${getConnectorAvatar(item).name} ${item.isDraft ? '(Draft)' : ''}`
            }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
            <v-menu :close-on-content-click="false" offset-x offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        medium
                        v-bind="attrs"
                        v-on="on"
                        v-if="checkRole(projectId, 'project:connector:edit')"
                    >
                        <v-icon>mdi-dots-vertical</v-icon></v-btn
                    >
                </template>

                <v-card min-width="198px">
                    <v-list>
                        <v-list-item
                            class="text-btn-wrapper"
                            @click="finishConnectorCreate(item)"
                            v-if="item.isDraft && checkRole(projectId, 'project:connector:edit')"
                        >
                            <span class="mx-3 my-2">Завершить подключение</span>
                        </v-list-item>
                        <v-list-item
                            class="text-btn-wrapper"
                            @click="openConnectorSettings(item)"
                            v-if="!item.isDraft && checkRole(projectId, 'project:connector:edit')"
                        >
                            <span class="mx-3 my-2">Настройки</span>
                        </v-list-item>

                        <v-list-item
                            class="text-btn-wrapper"
                            @click="deleteConnector(item)"
                            v-if="checkRole(projectId, 'project:connector:delete')"
                        >
                            <span class="mx-3 my-2">Удалить</span>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-menu>
        </template>
    </v-data-table>
</template>

<script>
// @ is an alias to /src
import { checkAccess } from '@/utils'
import ConnectorCard from '@/components/project/ConnectorCard'
import { checkRole } from '@root/src/helpers/roles'
export default {
    name: 'ConnectorsListView',
    components: { ConnectorCard },
    data: () => ({
        headers: [
            { text: 'Источники', value: 'connectorType', sortable: false },
            { text: '', value: 'actions', sortable: false, align: 'end', width: '1%' },
        ],
        selectedConnectors: [],
    }),
    props: {
        connectors: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        connectorsAvatars() {
            return this.$store.state.connectorsAvatars
        },
        projectId() {
            return this.$router.currentRoute.params.id
        },
        inputVal: {
            get() {
                return this.selectedConnectors
            },
            set(val) {
                this.$emit('input', val)
            },
        },
    },
    watch: {
        selectedConnectors() {
            this.inputVal = this.selectedConnectors
        },
    },
    methods: {
        checkAccess,
        checkRole,
        getConnectorAvatar(connector) {
            return (
                this.connectorsAvatars.find(avatar => avatar.type === connector.connectorType) || {
                    color: 'white',
                    symbol: '404',
                    name: 'Не найдено',
                }
            )
        },
        deleteConnector(connector) {
            this.$parent.$emit('deleteConnector', connector.connectorId)
        },
        openConnectorSettings(connector) {
            this.$parent.$emit('openConnectorSettings', connector.connectorId)
        },
        finishConnectorCreate(connector) {
            this.$store.commit('project/setLoggedAddingConnector', connector)
            this.$store.commit('project/updateCurrentAddingConnector', connector)
            this.$router.push(`/project/${connector.projectId}/addConnectorStage3`)
        },
    },
    created() {},
}
</script>
