import knowledgeBaseCardContent from '@/components/stories/storiesContent/knowledge-base/CardContent.vue'
import trainingCourseCardContent from '@/components/stories/storiesContent/training-course/CardContent.vue'
import devRoadmapCardContent from '@/components/stories/storiesContent/dev-roadmap/CardContent.vue'
import { Stories } from '../../../types/main'

export default {
    'knowledge-base': {
        id: 'knowledge-base',
        colors: {
            gradient1: '#4CB0EB',
            gradient2: '#7C72FF',
            shadow: '#65A7F166',
            slider: '#FFDA5D',
        },
        cardContent: knowledgeBaseCardContent,
        hrefTo: 'http://help.dataheroes.pro/?slug=823-znakomstvo-s-programmoj',
        slides: [],
    },
    'training-course': {
        id: 'training-course',
        colors: {
            gradient1: '#78FBEB',
            gradient2: '#0F6C5C',
            shadow: '#66D5C666',
            slider: '#FFDA5D',
        },
        cardContent: trainingCourseCardContent,
        hrefTo: 'https://edu.dataheroes.pro/',
        slides: [],
    },
    'dev-roadmap': {
        id: 'dev-roadmap',
        colors: {
            gradient1: '#FFC213',
            gradient2: '#B24A00',
            shadow: '#E79E6266',
            slider: '#FFDA5D',
        },
        cardContent: devRoadmapCardContent,
        hrefTo: 'http://help.dataheroes.pro/?slug=1052-novaya-funkcionalnost',
        slides: [],
    },
} as {
    [id: string]: Stories
}
