<template>
    <v-data-table
        class="mt-5 black--text user-table"
        :headers="headers"
        :items="dashBoardsLoading ? [] : mappedDashBoards"
        :loading="dashBoardsLoading"
        loading-text="Загрузка..."
        hide-default-footer
        @click:row="goToPath($event)"
        :item-class="'class'"
        :items-per-page="-1"
    >
        <template v-slot:[`item.name`]="{ item }" v-if="dashBoards">
            <span v-if="item.chartData">{{ item.chartData.isDemo ? `${item.name} (Демо)` : item.name }}</span>
            <span v-else>{{ item.name }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
            <v-menu :close-on-content-click="false" offset-x offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon :disabled="item.disabled" v-if="false"
                        ><v-icon> mdi-dots-vertical </v-icon></v-btn
                    >
                </template>

                <v-card min-width="198px">
                    <v-list>
                        <v-list-item class="text-btn-wrapper" @click="TODO">
                            <span class="mx-3 my-2">Скачать</span>
                        </v-list-item>
                        <v-list-item class="text-btn-wrapper" @click="TODO">
                            <span class="mx-3 my-2">Печать</span>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-menu>
        </template>
    </v-data-table>
</template>

<script>
export default {
    name: 'ListViewDashboards',
    components: {},
    props: {
        dashBoards: {
            type: Array,
            default: () => {},
        },
    },
    data: () => ({
        headers: [
            { text: 'Название', value: 'name', sortable: false },
            { text: '', value: 'actions', sortable: false, align: 'end', width: '1%' },
        ],
    }),
    computed: {
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        dashBoardsLoading() {
            if (this.project.id === 'loading') {
                return true
            } else {
                return false
            }
            /*   return this.dashBoards
                .map((dashBoard) => dashBoard.chartData)
                .some((chartData) => chartData === undefined) */
        },
        mappedDashBoards() {
            return this.dashBoards.map(el => {
                const connectors = this.project.connectors
                if (!el.requiredConnectorTypes) {
                    return Object.assign({ ...el }, { class: '' }, { disabled: false })
                }
                const disabled = this.project?.settings?.forcedShowWidgets
                    ? false
                    : !connectors.some(connector =>
                          el.requiredConnectorTypes.includes(connector.connectorType)
                      )

                return Object.assign({ ...el }, { class: disabled ? 'disabled' : '' }, { disabled })
            })
        },
    },

    methods: {
        goToPath(dashBoard) {
            if (dashBoard.disabled) {
                return
            }
            const currentRoute = this.$router.currentRoute.path
            this.$router.push(`${currentRoute}${dashBoard.path}`)
        },
        disabled(item) {
            const rowClass = 'disabled'
            return rowClass
        },
        TODO() {},
    },
    created() {},
}
</script>

<style lang="sass" scoped>
.navigation
    cursor: pointer
::v-deep tr
    cursor: pointer
    font-size: 16px !important
.list-view
    swidth: 1016px
    width: 100%

.v-list-item
    padding: 0 !important
    min-height: 0px
::v-deep .disabled
    opacity: 0.5 !important
    cursor: default !important
::v-deep .disabled:hover
    background-color: transparent !important
</style>
