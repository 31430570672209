<template>
    <v-icon class="edit-icon ml-2" @click="goToSettings">mdi-cog</v-icon>
</template>

<script>
export default {
    name: 'ProjectSettingsBtn',
    props: {
        projectId: {
            type: String,
        },
    },
    computed: {},
    components: {},
    data: () => ({}),
    methods: {
        goToSettings() {
            this.$router.push(`/project/${this.$route.params.id}/settings`)
        },
    },
    mounted() {},
}
</script>

<style lang="sass" scoped>
@import '@/vars.sass'
.edit-icon
    color: white
    opacity: 0.5
.edit-icon:hover
    opacity: 1
    color: $hint-icon-hover-color
    cursor: pointer
</style>
