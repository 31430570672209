<template>
    <v-dialog v-model="isDialog" width="720px">
        <v-sheet>
            <div>
                <v-card-title class="main white--text">Внимание</v-card-title>
                <div class="body pa-4">
                    <div class="bold">
                        {{ confirmText }}
                    </div>
                    <div class="mt-2">
                        Введите слово "Удалить" для подтверждения удаления
                    </div>

                    <TextField 
                    class="mt-2"
                    placeholder="Удалить"
                    hideDetails
                    v-model="text"
                    />

                    <div class="mt-2 red--text">
                        Если вы решите восстановить удалённый источник, то сможете это сделать не ранее чем через <b>30</b> дней
                    </div>

                    <div class="footer mt-4">
                        <v-btn variant="outlined" @click="close">Отмена</v-btn>
                        <v-btn 
                        variant="contained" 
                        color="error" 
                        class="white--text" 
                        :loading="loading" 
                        @click="deleteConnectors"
                        :disabled="text !== 'Удалить'"
                        >
                            <div class="btn-inner">
                                <v-icon size="20" color="white">mdi-delete-outline</v-icon>
                                Удалить
                            </div>
                        </v-btn>
                    </div>
                </div>
            </div>
        </v-sheet>
    </v-dialog>
</template>
<script>
import Vue, { PropType } from 'vue'
import _ from 'lodash'
import TextField from '../../inputs/TextField.vue'

export default Vue.extend({
    components: { TextField },
    props: {
        value: { type: Boolean },
        connectors : {type : Array}
    },
    data: () => ({
        loading : false,
        text : ""
    }),
    computed: {
        isDialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        isSingle() {
            return this.connectors.length > 1 ? false : true
        },
        connectorsCount() {
            return this.connectors.length
        },
        confirmText() {
            if (this.isSingle) {
                return "Вы действительно хотите удалить выбранный источник?"
            } else {
                return `Вы действительно хотите удалить выбранные источники (${this.connectorsCount})?`
            }
        }
    },
    methods: {
        async deleteConnectors() {
            this.loading = true
            let promises = []
            for (const connectorId of this.connectors) {
                promises.push(this.$store.dispatch('project/deleteConnector', connectorId))
            }
            await Promise.all(promises)
            this.loading = false
            this.close()
        },

        close() {
            this.text = ''
            this.isDialog = false
        },
    }
})
</script>

<style lang="scss" scoped>
    .bold {
        font-weight: 600;
    }
  .footer {
    display: flex;
    justify-content: space-between;
  }

  .btn-inner {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #fff;

    i {
        margin-top: -2px;
    }
  }

</style>
