<template>
    <div style="position: relative">
        <div class="menu-content" v-if="disabled">
            <div class="menu-content-text-wrapper">
                <h3>
                    Настройте
                    <router-link :to="addConnectorLink" v-if="checkAccess(project.id, 'admin')"
                        >«Источники»</router-link
                    >
                    <span v-else>«Источники»</span>
                </h3>
            </div>
        </div>

        <v-card
            width="328"
            outlined
            hover
            @click="goToPath"
            :id="id"
            :disabled="disabled"
            class="dashboard-card"
            :class="{ marketplace: type === 'marketplace' }"
            elevation="0"
        >
            <div class="dashboard-card__content">
                <div>
                    <div class="icon-circle" :style="{ backgroundColor: icon.color }">
                        <v-icon color="white"> {{ icon.name }}</v-icon>
                    </div>
                </div>
                <div class="space-between " @click="goToPath(project)">
                    <span class="dashboard-card__title" v-if="!disabled">
                        {{ name }}
                    </span>
                </div>
                <div v-if="projectStatus === 'lock'" class="alert-icon" :style="{ backgroundColor: 'red' }">
                    <v-icon color="white"> mdi-alert-circle-outline </v-icon>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
// @ is an alias to /src
import { checkAccess } from '@/utils'
import { useProject } from '@root/src/hooks/useProject'
import { useBillingStore } from '@root/src/store/stores/billing'
import { computed } from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'
////
export default {
    name: 'DashboardCard',
    components: {},
    data: () => ({}),
    setup(props, { emit }) {
        const { project } = useProject()
        const billingStore = computed(()=>useBillingStore())
        const currentTariff = computed(()=> billingStore.value.getCurrentTariffState(project.value.id))
        return {
            currentTariff,
            project,
        }
    },
    props: {
        id: {
            type: String,
            default: null,
        },
        requiredConnectorTypes: {
            type: Array,
            default: () => [],
        },

        name: {
            type: String,
            default: 'Название отчета',
        },
        icon: {
            type: Object,
            default: () => {},
        },
        path: {
            type: String,
            default: '/',
        },
        chartData: {
            type: Object,
            default: () => {},
        },
        type: {
            type: String,
            default: 'default',
        },
        projectStatus: {
            type: String,
            default: 'open',
        },
    },
    computed: {
        ...mapGetters(['projectById']),
        isDemo() {
            if (!this.chartData) {
                return false
            } else {
                if (this.chartData.isDemo) {
                    return true
                } else {
                    return false
                }
            }
        },
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        addConnectorLink() {
            return `/project/${this.project.id}/addConnectorStage1`
        },
        disabled() {
            const connectors = this.project.connectors
            if (!connectors) {
                return true
            }
            if (this.requiredConnectorTypes.length === 0) {
                return false
            }
            if (this.project?.settings?.forcedShowWidgets) {
                return false
            }
            return !connectors.some(
                connector =>
                    this.requiredConnectorTypes.includes(connector.connectorType) && !connector.isDraft
            )
        },
    },
    methods: {
        checkAccess,
        goToPath() {
            const currentRoute = this.$router.currentRoute.path
            this.$router.push(`${currentRoute}${this.path}`.replaceAll('//','/'))
        },
        TODO() {
            //TODO:действия в конетексном меню в карточках отчеов
        },
    },
    created() {},
}
</script>
<style lang="sass" scoped>
.dashboard-card
    &.marketplace
        background: radial-gradient(ellipse at left top, #FFFFFF 5%, #BDDFFB)
        border: 1px solid #BDDFFB
    &__content
        display: flex
        align-items: center

    &__title
        font: normal normal 500 20px/24px Roboto

.alert-icon
    width: 12px
    height: 12px
    min-width: 12px
    min-height: 12px
    border-radius: 50%
    position: absolute
    right: 6px
    top: 6px
    display: flex
    justify-content: center
    align-items: center
.icon-circle
    width: 40px
    height: 40px
    min-width: 40px
    min-height: 40px
    border-radius: 100%
    display: flex
    justify-content: center
    align-items: center
    margin-top: 16px
    margin-bottom: 16px
    margin-left: 16px
    margin-right: 16px


.v-list-item
    padding: 0 !important
    min-height: 0px

button:focus
    outline: 0

.menu
    box-shadow: none !important
    opacity: 1 !important

.menu-content
    position: absolute
    z-index: 1
    display: flex
    justify-content: center
    align-items: center
    width: 328px
    padding-left: 66px
    height: 72px
    opacity: 1 !important
    background-color: rgba(255, 255, 255,0.4 )
.menu-content-text-wrapper
    user-select: all
    width: 250px
    opacity: 1 !important
.space-between
    display: flex
    align-items: center
    justify-content: space-between
    display: feColorMatrix
</style>
