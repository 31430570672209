
import { computed, defineComponent, getCurrentInstance, onMounted, ref } from 'vue'
import HintIcon from '../../HintIcon.vue'
import api from '../../../api/api'
import { ScheduledReport } from '../../../types/scheduledReport'
import useRouter from '../../../hooks/useRouter'
import { checkRole } from '../../../helpers/roles'

export default defineComponent({
    components: { HintIcon },
    setup(props, {}) {
        const root = getCurrentInstance()!.proxy
        console.log(root.$store)
        const tableHeaders = [
            { text: 'Название', value: 'title' },
            { text: 'Время', value: 'sendAt' },
            { text: '', value: 'actions', sortable: false, align: 'end' },
        ]
        const { router, route } = useRouter(root)
        const dataLoading = ref(true)
        const sheduledReports = ref<ScheduledReport[]>([])
        const project = computed(() => root.$store.getters.projectById(route.value.params.id))
        onMounted(async () => {
            dataLoading.value = true
            sheduledReports.value = await api.scheduledReport
                .getManyByProject(project.value.id)
                .then(res => res.data)
            dataLoading.value = false
        })
        const deleteScheduledReport = async (scheduledReport: ScheduledReport) => {
            dataLoading.value = true
            await api.scheduledReport.remove({ projectId: project.value.id, id: scheduledReport.id })
            sheduledReports.value = sheduledReports.value.filter(el => el.id !== scheduledReport.id)
            dataLoading.value = false
        }
        const editScheduledReport = async (scheduledReport: ScheduledReport) => {
            router.push(`/project/${project.value.id}/scheduledReport?mode=edit&id=${scheduledReport.id}`)
        }
        const createScheduledReport = async (scheduledReport: ScheduledReport) => {
            router.push(`/project/${project.value.id}/scheduledReport?mode=create`)
        }
        return {
            checkRole,
            project,
            tableHeaders,
            sheduledReports,
            deleteScheduledReport,
            editScheduledReport,
            createScheduledReport,
            dataLoading,
        }
    },
})
