
import { defineComponent, ref, computed, onMounted, getCurrentInstance } from 'vue'
import StoriesCard from './StoriesCard.vue'
import { useStoriesStore } from '../../store/stores/stories/stories'
import useRouter from '../../hooks/useRouter'
export default defineComponent({
    props: {},
    components: { StoriesCard },
    setup(props, { emit }) {
        const root = getCurrentInstance()!.proxy
        const COLUMNS = 3

        const { route } = useRouter(root)
        const storiesStore = useStoriesStore()
        const projectId = computed(() => route.value.params.id)
        const loading = ref(true)

        const stories = computed(() => {
            if (loading.value) return [{ id: '1' }, { id: '2' }, { id: '3' }]
            return storiesStore.getProjectStories(projectId.value)
        })
        onMounted(async () => {
            loading.value = true
            await storiesStore.fetchProjectStories(projectId.value)
            loading.value = false
        })
        return { COLUMNS, stories, loading }
    },
})
