<template>
    <v-dialog width="500" v-model="isRenameUserDialog" @click:outside="cancel">
        <v-card tile>
            <v-card-title class="headline main white--text"> Переименовать пользователя </v-card-title>
            <v-card-text>
                <v-form v-model="valid" class="mt-6" ref="form">
                    <v-text-field
                        color="main"
                        filled
                        v-model="name"
                        label="Введите имя пользователя"
                        required
                    ></v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn color="main" class="ml-3 mb-5 white--text" :disabled="!valid" @click="renameUser">
                    Переименовать
                </v-btn>
                <v-btn outlined class="ml-4 mb-5" @click="cancel"> Отмена </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'RenameUserDialog',
    props: {
        projectId: String,
    },
    components: {},
    data: () => ({
        valid: false,
        name: '',
        //isLoading: true,
    }),
    computed: {
        isRenameUserDialog: {
            get() {
                return this.$store.state.project.isRenameUserDialog
            },
            set(value) {
                this.$store.commit('project/setIsRenameUserDialog', value)
            },
        },
        project() {
            const projectId = this.$router.currentRoute.params.id
            return this.$store.getters.projectById(projectId)
        },
        currentUserIdToRename() {
            return this.$store.state.project.currentUserIdToRename
        },
    },
    watch: {
        isRenameUserDialog() {
            if (this.isRenameUserDialog) {
                const user = this.project.users.find((user) => user.id === this.currentUserIdToRename)
                this.name = user.name
            }
        },
    },
    methods: {
        async renameUser() {
            this.$store.dispatch('project/changeUserName', {
                projectId: this.project.id,
                userId: this.currentUserIdToRename,
                name: this.name,
            })
            this.cancel()
        },
        cancel() {
            this.$refs.form.reset()
            this.$refs.form.resetValidation()
            this.$store.commit('project/setIsRenameUserDialog', false)
        },
    },

    created() {},
    mounted() {},
}
</script>

<style lang="sass" scoped>
@import '@/vars.sass'
button
    border-color: #0000001F
    outline: 0
.user-btn
    border: 1px solid #0000001F
    border-radius: 16px
    color: black
    padding: 4px 12px 4px 12px
.user-btn-active
    border: 1px solid $main
    background-color: $main-light
</style>
