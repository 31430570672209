import { defineStore } from 'pinia'
import { getNotifications, readNotification } from '@/api/notification'
import { ProductMetricsIds, Stories } from '../../../types/main'
import storiesList from './storiesList'
import _ from 'lodash'
import api from '../../../api/api'
import { sleep } from '../../../utils'

interface StoriesWithProjectId extends Stories {
    projectId: string
}

export const useStoriesStore = defineStore('stories', {
    state: () => ({
        stories: [] as StoriesWithProjectId[],
    }),
    actions: {
        async fetchProjectStories(projectId: string) {
            const newStories: StoriesWithProjectId[] = []
            newStories.push(Object.assign({ projectId }, storiesList['knowledge-base']))
            newStories.push(Object.assign({ projectId }, storiesList['training-course']))
            newStories.push(Object.assign({ projectId }, storiesList['dev-roadmap']))
            this.stories = [...this.stories.filter(el => el.projectId !== projectId), ...newStories]
        },
    },
    getters: {
        getProjectStories(state) {
            return (projectId: string) => state.stories.filter(el => el.projectId === projectId)
        },
    },
})
