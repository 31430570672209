<template>
    <v-menu v-model="menuIsOpen" :close-on-content-click="false" :nudge-width="200" offset-x offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="mr-2 thin-btn"
                outlined
                v-bind="attrs"
                v-on="on"
                :disabled="selectedUsers.length === 0"
                ><v-icon>mdi-menu-down</v-icon>Назначить права</v-btn
            >
        </template>

        <v-card min-width="198px">
            <v-list dense>
                <v-list-item class="mx-3"><span class="black--text text-subtitle-2">ПРАВА</span></v-list-item>
                <v-list-item
                    v-for="role in roles"
                    :key="role.id"
                    class="text-btn-wrapper"
                    @click="reAssignPermissionMany(role.id)"
                >
                    <span class="mx-3 my-2">{{ role.name }}</span>
                </v-list-item>
            </v-list>
        </v-card>
    </v-menu>
</template>


<script>
import { mapState } from 'vuex'
export default {
    name: 'AssignPermissionBtn',
    props: {
        selectedUsers: {
            type: Array,
            default: () => [],
        },
        projectId: {
            type: String,
            default: null,
        },
    },
    components: {},
    data: () => ({
        menuIsOpen: false,
    }),
    computed: {
        ...mapState(['roles']),
    },
    methods: {
        async reAssignPermissionMany(roleId) {
            const userIds = this.selectedUsers.map((user) => user.id)
            for (const userId of userIds) {
                await this.$store.dispatch('project/reAssignPermission', {
                    projectId: this.projectId,
                    userIdtoChange: userId,
                    roleId,
                })
            }
            this.menuIsOpen = false
            this.$parent.$emit('clearSelectedUsers')
        },
    },
    created() {},
}
</script>
