<template>
    <v-row class="mt-4">
        <v-row v-if="!dashBoards.length || projectStatus !== 'open'" justify="center">
            <div>
                Для отображения доступных виджетов
                <span v-if="projectStatus === 'beforeTrial'"
                    ><a @click="goToBotConnectorCreate">подключите бота</a> или</span
                >
                <a @click="goToBilling"> приобретите тариф</a>
            </div>
        </v-row>
        <DashboardCard
            v-if="projectStatus === 'open'"
            v-for="(item, i) in dashBoards"
            :key="i"
            :id="item.id"
            :requiredConnectorTypes="item.requiredConnectorTypes"
            :class="i % 3 === 0 ? 'ml-3 mr-2 my-2' : 'ma-2'"
            :chartData="item.chartData"
            :name="item.name"
            :path="item.path"
            :icon="item.icon"
            :type="item.type"
        ></DashboardCard>
    </v-row>
</template>

<script>
import DashboardCard from '@/components/project/DashboardCard'
import router from '@root/src/router'
import { Li } from '@root/src/router'
export default {
    name: 'CardsViewDashboards',
    components: { DashboardCard },
    props: {
        projectId: {
            type: String,
            default: null,
        },
        dashBoards: {
            type: Array,
            default: () => {},
        },
        projectStatus: {
            type: String,
            default: 'open',
        },
    },
    data: () => ({}),
    computed: {},
    methods: {
        goToBilling(e) {
            router.replace(`/project/${this.projectId}/billing/?section=tariffs`)
        },
        goToBotConnectorCreate() {
            router.push(`/project/${this.projectId}/addConnectorStage3?complete=true`)
        },
    },
    created() {},
}
</script>
