
import { computed, defineComponent, ref, PropType, watch, onMounted, onUnmounted, nextTick } from 'vue'
import * as _ from 'lodash'
import { Stories } from '../../../types/main'

const DELTA_TIME_INTERVAL = 50

export default defineComponent({
    props: {
        stories: { type: Object as PropType<Stories>, required: true },
        value: { type: Number, required: true },
        slideDuration: { type: Number, required: true },
    },
    setup(props, { emit }) {
        const slides = ref(props.stories.slides)
        const disableSliderAnimation = ref(true)
        const deltaTimeInterval = ref()
        const deltaTime = ref(0)
        const getSliderProgress = (slideIndex: number) => {
            if (slideIndex < props.value) {
                return 100
            }
            if (slideIndex === props.value) {
                return (deltaTime.value / props.slideDuration) * 100
            }
        }
        const setSlide = (slideIndex: number) => {
            disableSliderAnimation.value = false
            emit('input', slideIndex)
            deltaTime.value = 0
            nextTick(() => {
                disableSliderAnimation.value = true
            })
        }
        onMounted(() => {
            emit('input', 0)
            deltaTime.value = 0
            deltaTimeInterval.value = setInterval(() => {
                deltaTime.value += DELTA_TIME_INTERVAL
                if (deltaTime.value > props.slideDuration) {
                    if (props.value + 1 !== props.stories.slides.length) {
                        emit('input', props.value + 1)
                        deltaTime.value = 0
                    }
                }
            }, DELTA_TIME_INTERVAL)
        })
        onUnmounted(() => {
            clearInterval(deltaTimeInterval.value)
        })
        return { getSliderProgress, setSlide, disableSliderAnimation }
    },
})
